import { useState } from "react";
import Fruit from "./components/Fruit";
import FruitForm from "./components/FruitForm";

export default App;

function App() {
    // state (�tat, donn�es)
    const [fruits, setFruits] = useState([
        { id: 1, nom: "Abricot" },
        { id: 2, nom: "Banane" },
        { id: 3, nom: "Cerise" }
    ]);

    // comportements
    const handleDelete = (id) => {
        // 1. copie du state
        const fruitsCopy = [...fruits];

        // 2. manipuler le state
        const fruitsCopyUpdated = fruitsCopy.filter((fruit) => fruit.id !== id);

        // 3. modifier mon state
        setFruits(fruitsCopyUpdated);
    };

    const handleAdd = (fruitAAjouter) => {
        const fruitsCopy = [...fruits];

        fruitsCopy.push(fruitAAjouter);

        setFruits(fruitsCopy);
    };

    // affichage (render)
    return (
        <div>
           {/*<h1>Liste de fruits</h1>*/}
           {/* <ul>*/}
           {/*     {fruits.map((fruit) => (*/}
           {/*         <Fruit*/}
           {/*             fruitInfo={fruit}*/}
           {/*             onFruitDelete={handleDelete}*/}
           {/*             key={fruit.id}*/}
           {/*         />*/}
           {/*     ))}*/}
           {/* </ul>*/}
            {/* <FruitForm handleAdd={handleAdd} />*/}
            <h1>Site en cours de maintenance !</h1>
        </div>
    );
}

// Gestion du formulaire
// 1. Cr�ation du formulaire
// 2. Soumission du formulaire
// 3. Collecte des donn�es du formulaire
// R�gle : on acc�de jamais au DOM directement /!\
